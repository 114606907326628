import React, { useState } from "react";
import video from "./FuzhouChain-NFT-final_small.mp4";
import Logo from "./cllogo.svg";

const CookiesBanner = ({ onCookiesAccept }) => {
  return (
    <div className="mw8 fixed top-1-l right-2-l left-2-l bg-white br2 pa4 w-50-l w-100 center" style={{ zIndex: 99 }}>
      <p className="ma0 pa0 mb2 black">
        We use necessary cookies to make our site work and, if you consent, analytics and/or third party cookies to personalize website content and analyze traffic. By clicking on 'Accept' you agree to the use of all cookies. If you only want to allow necessary cookies, click on 'Decline'. For more details please see our <a href="//casper.network/legal/privacy">Privacy Policy</a>
      </p>
      <button className="bg-white gray bn dim pointer" onClick={() => onCookiesAccept() } bgColor="bg-navy" fontColor="white">Accept</button>
      <button className="bg-white gray bn dim pointer" >Decline</button>
    </div>
  );
};

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [activeKey, setActiveKey] = useState("");

  React.useEffect(() => {
    const item = localStorage.getItem('cookies-accept');
    if (!item) {
      setCookiesAccepted(false);
    }
  }, []);

  const onCookiesAccept = () => {
    localStorage.setItem('cookies-accept', true);
    setCookiesAccepted(true);
  }

  return (
    <article className="vh-100 dt w-100 bg-black bg-our">
      {!cookiesAccepted ? <CookiesBanner onCookiesAccept={onCookiesAccept} /> : null}
      <div className="dtc v-mid tc white ph3 ph4-l">
        <div className="video-wrapper">
          <video autoPlay muted loop className="video-player">
            <source src={video} type="video/mp4" />
          </video>
          <div className="text">
            <a className="no-underline white" target="_blank" href="https://cspr.live/deploy/b3272b2a4e8d06972928e94cd5a7be1f8b4299efccdfdf451f9517a976eac6b7">View Mint Deploy</a>
            <br/>
            <br/>
            <a className="no-underline white" target="_blank" href="https://gateway.pinata.cloud/ipfs/QmUifMYpqxrwXn13Afh28Vo9db2CQLrJgCMhwRxqyKbkUS?preview=1">View on IPFS</a>
          </div>
        </div>
        <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-61e829bde95b1b3a"></script>
        <div className="addthis_inline_share_toolbox_jvm0"></div>   
      </div>
      <div className="fixed bottom-0 left-0 footer w-100 mt5 bg-black">
        <div className="mw8 center flex-l pt2 pb4-l">
          <div className="flex flex-column w-50-l w100 justify-center items-center items-start-l">
            <a href="//casper.network/en/network" className="no-underline gray mv1">Casper Association</a>
            <a href="//casper.network/network/trademark-policy" className="no-underline gray mv1">Trademark Policy & Guidelines</a>
          </div>
          <div className="flex flex-column w-50-l w-100 justify-end-l justify-center pt4 pt0-l">
            <a href="//casper.network/" target="_blank" className="no-underline gray mv2 self-end-l self-center"><img src={Logo} style={{ width: 100 }}/></a>
            <p className="gray self-end-l self-center mt1 mb0">© 2022 Casper</p>
          </div>
        </div>
      </div>
    </article>
  );
}

export default App;
